const ID_TOKEN_KEY = "id_token" as string;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save role into localStorage
 * @param user: unknown
 */
export const saveUser = (user) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("user");
  window.localStorage.clear();
};

export default { getToken, saveToken, destroyToken, saveUser };
