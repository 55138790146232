import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface addUser {
  first_name: string;
  last_name: string;
  email: string;
  linkedin: string;
  priority: number;
  is_display: number;
  job_title: string;
  contact_no: string;
  role_id: number;
  status: number;
  image: string;
}

export interface RoleDetail {
  id: number;
  name: string;
  code: string;
  status: number;
}

export interface UserList {
  first_name: string;
  last_name: string;
  email: string;
  linkedin: string;
  priority: number;
  is_display: number;
  job_title: string;
  contact_no: string;
  role_id: number;
  status: number;
  image: string;
  id: number;
  role: RoleDetail;
}

export interface UserDel {
  id: number;
}

export interface UserInfo {
  errors: unknown;
  addUsersData: addUser;
  updateUsersData: UserList;
  deleteUserData: UserDel;
  userImageDetails: UserList;
  userData: IUserTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IUserTableData extends IBaseTable {
  data: Array<UserList> | [];
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
  errors = {};
  addUsersData = {} as addUser;
  updateUsersData = {} as UserList;
  userImageDetails = {} as UserList;
  deleteUserData = {} as UserDel;
  userData = {} as IUserTableData;

  get getSolicitorUserError() {
    return this.errors;
  }
  get solicitorAddUser() {
    return this.addUsersData;
  }
  get solicitorUpdateUser() {
    return this.updateUsersData;
  }
  get solicitorDeleteUser() {
    return this.deleteUserData;
  }

  get getSolicitorUsersList() {
    return this.userData;
  }

  get solicitorUpdateUserImage() {
    return this.userImageDetails;
  }

  // create mutation

  @Mutation
  SET_SOLICITOR_USER_UPDATE_IMAGE(data) {
    this.userImageDetails = data;
  }

  @Mutation
  SET_SOLICITOR_USER_ADD(data) {
    this.addUsersData = data;
  }
  @Mutation
  SET_SOLICITOR_USER_UPDATE(data) {
    this.updateUsersData = data;
  }
  @Mutation
  SET_SOLICITOR_USER_DELETE(data) {
    this.deleteUserData = data;
  }
  @Mutation
  SET_SOLICITOR_USERS(data) {
    this.userData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  SOLICITOR_USER_ADD(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/staff/create`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_USER_ADD", response);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_USER_UPDATE_IMAGE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/staff/update-image`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_USER_UPDATE_IMAGE", response);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_USER_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/staff/update`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_USER_UPDATE", response);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_USER_DELETE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/staff/delete`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_USER_DELETE", response);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_USERS(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/staff/get`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_USERS", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
