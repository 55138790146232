import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface ISubscriberData {
  name: string;
  phone: string;
  address_1: string;
  address_2: string;
  city: string;
  postcode: string;
  plan_name: string;
}
export interface BillingList {
  name: string;
  status_name: string;
  status: string;
  id: number;
  subscriber: ISubscriberData;
}

export interface BillingInfo {
  errors: unknown;
  billingData: IBillingTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IBillingTableData extends IBaseTable {
  data: Array<BillingList> | [];
}

@Module
export default class BillingModule extends VuexModule implements BillingInfo {
  errors = {};
  billingData = {} as IBillingTableData;

  get getSolicitorBillingError() {
    return this.errors;
  }
  get getSolicitorBillingData() {
    return this.billingData;
  }

  // create mutation
  @Mutation
  SET_GET_SOLICITOR_BILLING(data) {
    this.billingData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  // define action

  @Action
  GET_SOLICITOR_BILLING(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/solicitor/payment/get-payments`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_GET_SOLICITOR_BILLING", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
