import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
  access: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  isForgotPassword: boolean;
  isResetPassword: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = false;
  isForgotPassword = false;
  isResetPassword = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated() {
    return this.isAuthenticated;
  }

  get isForgotPasswordSuccess(): boolean {
    return this.isForgotPassword;
  }

  get isResetPasswordSuccess(): boolean {
    return this.isResetPassword;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get loginError() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    console.log(error);
    this.errors = {};
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data;
    JwtService.saveToken(user.data.token);
    JwtService.saveUser(user.data);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  SET_FORGOT_PASSWORD() {
    this.isForgotPassword = true;
  }

  @Mutation
  SET_RESET_PASSWORD() {
    this.isResetPassword = true;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("auth/login", credentials)
      .then(({ data }) => {
        if (data.result) {
          this.context.commit(Mutations.SET_AUTH, data);
        } else {
          this.context.commit(Mutations.SET_ERROR, data.errors);
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("auth/password/create", payload)
      .then(({ data }) => {
        if (data.result) {
          this.context.commit("SET_FORGOT_PASSWORD");
        } else {
          this.context.commit(Mutations.SET_ERROR, data.errors);
        }
      })
      .catch(({ response }) => {
        this.context.dispatch("SET_TOAST", response.data);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  RESET_PASSWORD(payload) {
    return ApiService.post("auth/password/reset", payload)
      .then(({ data }) => {
        if (data.result) {
          this.context.commit("SET_RESET_PASSWORD");
        } else {
          this.context.commit(Mutations.SET_ERROR, data.errors);
        }
      })
      .catch(({ response }) => {
        this.context.dispatch("SET_TOAST", response.data);
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      // As there is no api for verify token so comment that out.
      // ApiService.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
