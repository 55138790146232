import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface UpdateEnquiry {
  status: unknown;
}

export interface LawAreaList {
  name?: string;
  id?: number;
  status: number;
  pivot?: PivotList;
}
export interface PivotList {
  case_data_id: number;
  law_area_id: number;
}
export interface CourtListData {
  id: number;
  name: unknown;
}
export interface SolicitorListData {
  id: number;
  name: string;
}
export interface UserListData {
  id: number;
  first_name: string;
  last_name: string;
}
export interface EnquiryList {
  id: number;
  solicitor_id: number;
  court_start_date: string;
  court_end_date: string;
  created_at: string;
  created_date: string;
  experties: number;
  experties_name: string;
  funding_type: number;
  funding_type_name: string;
  law_area?: LawAreaList;
  court?: CourtListData;
  solicitor?: SolicitorListData;
  user?: UserListData;
}

export interface EnquiryInfo {
  errors: unknown;
  updateEnquiryData: UpdateEnquiry;
  enquiryData: IEnquiryTableData;
  enquiryShowData: EnquiryList;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IEnquiryTableData extends IBaseTable {
  data: Array<EnquiryList> | [];
}

@Module
export default class EnquiryModule extends VuexModule implements EnquiryInfo {
  errors = {};
  updateEnquiryData = {} as UpdateEnquiry;
  enquiryData = {} as IEnquiryTableData;
  enquiryShowData = {} as EnquiryList;

  get getErrorsEnquiry() {
    return this.errors;
  }
  get updateEnquiryDataModal() {
    return this.updateEnquiryData;
  }
  get getEnquiryDataById() {
    return this.enquiryShowData;
  }
  get getAllEnquiryData() {
    return this.enquiryData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_UPDATE_ENQUIRY](data) {
    this.updateEnquiryData = data;
  }

  @Mutation
  [Mutations.SET_ENQUIRY_LIST](data) {
    this.enquiryData = data;
  }

  @Mutation
  [Mutations.SET_SHOW_ENQUIRY](data) {
    this.enquiryShowData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.UPDATE_ENQUIRY](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/enquiries/update-status`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_UPDATE_ENQUIRY, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.SHOW_ENQUIRY](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/enquiries/show`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_SHOW_ENQUIRY, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.ENQUIRY_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/enquiries/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ENQUIRY_LIST, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
