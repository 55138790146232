import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface ChamberList {
  id: number;
  name: string;
  slug: string;
  address_1: string;
  address_2: string;
  city: string;
  county: string;
  postcode: string;
  plan_name: string;
  phone: string;
  email: string;
}

export interface StoreInfo {
  ChamberList: ChamberList;
  ShowChamberById: ChamberList;
  SignupAndClaimData: ChamberList;
  verifyAccountClaimData: ChamberList;
  errors: unknown;
}

@Module
export default class ChamberModule extends VuexModule implements StoreInfo {
  ChamberList = {} as ChamberList;
  ShowChamberById = {} as ChamberList;
  SignupAndClaimData = {} as ChamberList;
  verifyAccountClaimData = {} as ChamberList;
  errors = {};

  get getAvailableChambersList() {
    return this.ChamberList;
  }

  get getSelectedChamberDetailsById() {
    return this.ShowChamberById;
  }

  get getChamberSignupAndClaim() {
    return this.SignupAndClaimData;
  }

  get getChamberVerifyAccountClaimData() {
    return this.verifyAccountClaimData;
  }

  get getChamberSignupError() {
    return this.errors;
  }

  @Mutation
  SET_CHAMBER_QUICK_SIGNUP(data) {
    console.log(data);
    const obj = JSON.parse(localStorage.getItem("chamber_signup_data") || "{}");
    localStorage.setItem(
      "chamber_signup_data",
      JSON.stringify({ ...obj, ...data })
    );
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Mutation
  SET_SELECTED_CHAMBER_DETAILS(data) {
    this.ShowChamberById = data;
  }

  @Mutation
  SET_PUBLIC_CHAMBERS_LIST(data) {
    this.ChamberList = data;
  }

  @Mutation
  SET_CHAMBER_SIGNUP_AND_CLAIM(data) {
    this.SignupAndClaimData = data;
  }

  @Mutation
  SET_CHAMBER_VERIFY_ACCOUNT_CLAIM_TOKEN(data) {
    this.verifyAccountClaimData = data;
  }

  @Action
  GET_PUBLIC_CHAMBERS_LIST(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/public/sign-up/chamber/get`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_PUBLIC_CHAMBERS_LIST",
            response.data.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_SELECTED_CHAMBER_DETAILS(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/public/sign-up/chamber/show`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_SELECTED_CHAMBER_DETAILS",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_SIGNUP_AND_CLAIM(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/public/sign-up/chamber/sign-up-and-claim`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_CHAMBER_SIGNUP_AND_CLAIM",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_VERIFY_ACCOUNT_CLAIM_TOKEN(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/public/chamber/verify-account-claim-token`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_CHAMBER_VERIFY_ACCOUNT_CLAIM_TOKEN",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
