import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface UpdateEnquiry {
  status: unknown;
}

export interface LawAreaList {
  name?: string;
  id?: number;
  status: number;
  pivot?: PivotList;
}
export interface PivotList {
  case_data_id: number;
  law_area_id: number;
}
export interface CourtListData {
  id: number;
  name: unknown;
}
export interface SolicitorListData {
  id: number;
  name: string;
}
export interface UserListData {
  id: number;
  first_name: string;
  last_name: string;
}
export interface EnquiryList {
  id: number;
  solicitor_id: number;
  court_start_date: string;
  court_end_date: string;
  created_at: string;
  created_date: string;
  experties: number;
  experties_name: string;
  funding_type: number;
  funding_type_name: string;
  law_area?: LawAreaList;
  court?: CourtListData;
  solicitor?: SolicitorListData;
  user?: UserListData;
}

export interface InterestedList {
  id: number;
  case_data_id: number;
  chamber_id: number;
  status: number;
}

export interface EnquiryInfo {
  errors: unknown;
  updateEnquiryData: UpdateEnquiry;
  enquiryData: IEnquiryTableData;
  enquiryShowData: EnquiryList;
  markInterestedData: InterestedList;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IEnquiryTableData extends IBaseTable {
  data: Array<EnquiryList> | [];
}

@Module
export default class EnquiriesModule extends VuexModule implements EnquiryInfo {
  errors = {};
  updateEnquiryData = {} as UpdateEnquiry;
  enquiryData = {} as IEnquiryTableData;
  enquiryShowData = {} as EnquiryList;
  markInterestedData = {} as InterestedList;

  get getChamberEnquiryError() {
    return this.errors;
  }
  get chmaberEnquiryUpdate() {
    return this.updateEnquiryData;
  }
  get getChamberEnquiryById() {
    return this.enquiryShowData;
  }
  get getChamberEnquiryList() {
    return this.enquiryData;
  }

  get getChamberMarkInterestedData() {
    return this.markInterestedData;
  }

  // create mutation
  @Mutation
  SET_CHAMBER_ENQUIRY_UPDATE(data) {
    this.updateEnquiryData = data;
  }

  @Mutation
  SET_CHAMBER_ENQUIRIES(data) {
    this.enquiryData = data;
  }

  @Mutation
  SET_CHAMBER_ENQUIRY_SHOW(data) {
    this.enquiryShowData = data;
  }

  @Mutation
  SET_CHAMBER_ENQUIRY_MARK_INTERESTED(data) {
    this.markInterestedData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  CHAMBER_ENQUIRY_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/chamber/case/change-case-status`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_ENQUIRY_UPDATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_ENQUIRY_SHOW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/case/get-case-by-id`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_ENQUIRY_SHOW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CHAMBER_ENQUIRIES(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/case/get-enquiry`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_ENQUIRIES", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_ENQUIRY_MARK_INTERESTED(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/chamber/case/change-case-status`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_CHAMBER_ENQUIRY_MARK_INTERESTED",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
