import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import CustomerModules from "@/store/modules/CustomerModules";
import ChamberModules from "@/store/modules/ChambersModule";
import CourtModule from "@/store/modules/CourtModule";
import RegionModule from "@/store/modules/RegionModule";
import UserModule from "@/store/modules/UserModule";
import CircuitModule from "@/store/modules/CircuitModule";
import SolicitorModule from "@/store/modules/SolicitorModule";
import EnquiryModule from "@/store/modules/EnquiryModule";
import CaseModule from "@/store/modules/CaseModule";
import HappyPathModule from "@/store/modules/HappyPathModule";
import GetAddressModule from "@/store/modules/GetAddressModule";
import MetaDataModule from "@/store/modules/MetaDataModule";
import ReviewModule from "@/store/modules/ReviewModule";
import BilingModule from "@/store/modules/BilingModule";
import SolicitorQuickSignupModule from "@/store/modules/QuickSignup/SolicitorModule";
import ChamberQuickSignupModule from "@/store/modules/QuickSignup/ChamberModule";
import ChamberDashboardModule from "@/store/modules/Chamber/DashboardModule";
import ChamberEnquiriesModule from "@/store/modules/Chamber/EnquiriesModule";
import ChamberCasesModule from "@/store/modules/Chamber/CasesModule";
import ChamberUsersModule from "@/store/modules/Chamber/UsersModule";
import ChamberReviewsModule from "@/store/modules/Chamber/ReviewsModule";
import ChamberBillingModule from "@/store/modules/Chamber/BillingModule";

import SolicitorDashboardModule from "@/store/modules/Solicitor/DashboardModule";
import SolicitorCasesModule from "@/store/modules/Solicitor/CasesModule";
import SolicitorUsersModule from "@/store/modules/Solicitor/UsersModule";
import SolicitorReviewsModule from "@/store/modules/Solicitor/ReviewsModule";
import SolicitorBillingModule from "@/store/modules/Solicitor/BillingModule";

import Notification from "@/store/modules/Notification";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    CustomerModules,
    ChamberModules,
    CourtModule,
    RegionModule,
    UserModule,
    CircuitModule,
    SolicitorModule,
    EnquiryModule,
    CaseModule,
    HappyPathModule,
    GetAddressModule,
    MetaDataModule,
    ReviewModule,
    BilingModule,

    SolicitorQuickSignupModule,
    ChamberQuickSignupModule,

    ChamberDashboardModule,
    ChamberEnquiriesModule,
    ChamberUsersModule,
    ChamberCasesModule,
    ChamberReviewsModule,
    ChamberBillingModule,

    SolicitorDashboardModule,
    SolicitorUsersModule,
    SolicitorCasesModule,
    SolicitorReviewsModule,
    SolicitorBillingModule,

    Notification,
  },
});

export default store;
