import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface ReviewableList {
  id: number;
  name: string;
  status_name: string;
  plan_name: string;
}
export interface SourcableList {
  id: number;
  name: string;
  slug: string;
  plan_name: string;
}
export interface ReviewList {
  id: number;
  rating: number;
  case_data_id: number;
  reviewable_id: number;
  sourceable_id: number;
  status: number;
  created_at: string;
  comment: string;
  reviewable_type: string;
  sourceable_type: string;
  created_date: string;
  reviewable?: ReviewableList;
  sourcable?: SourcableList;
}

export interface StoreInfo {
  errors: unknown;
  reviewData: ReviewTableData;
  reviewShowData: ReviewList;
  reviewUpdate: ReviewUpdate;
  reportReviewData: ReviewUpdate;
  reviewChamberCreate: ReviewCreate;
}

export interface ReviewCreate {
  case_data_id: number;
  solicitor_id: number;
  review_id: number;
  rating: number;
  comment: string;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ReviewTableData extends IBaseTable {
  data: Array<ReviewList> | [];
}

export interface ReviewUpdate {
  case_data_id: number;
  solicitor_id: number;
  review_id: number;
  rating: number;
  comment: string;
}

@Module
export default class ReviewsModule extends VuexModule implements StoreInfo {
  errors = {};
  reviewData = {} as ReviewTableData;
  reviewShowData = {} as ReviewList;
  reviewUpdate = {} as ReviewUpdate;
  reportReviewData = {} as ReviewUpdate;
  reviewChamberCreate = {} as ReviewCreate;

  get getChamberReviewError() {
    return this.errors;
  }
  get getChamberReviewList() {
    return this.reviewData;
  }

  get chmaberReviewUpdate() {
    return this.reviewUpdate;
  }

  get getChamberReviewById() {
    return this.reviewShowData;
  }

  get chamberReportReview() {
    return this.reportReviewData;
  }

  get chamberReviewCreate() {
    return this.reviewChamberCreate;
  }

  @Mutation
  SET_CHAMBER_REVIEWS(data) {
    this.reviewData = data;
  }

  @Mutation
  SET_CHAMBER_REVIEW_SHOW(data) {
    this.reviewShowData = data;
  }

  @Mutation
  SET_CHAMBER_REPORT_REVIEW(data) {
    this.reportReviewData = data;
  }

  @Mutation
  SET_CHAMBER_REVIEW_UPDATE(data) {
    this.reviewUpdate = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Mutation
  SET_CHAMBER_REVIEW_CREATE(data) {
    this.reviewChamberCreate = data;
  }

  @Action
  CHAMBER_REVIEW_CREATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/review/create`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_REVIEW_CREATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_REPORT_REVIEW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/review/report`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_REPORT_REVIEW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_REVIEW_SHOW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/review/show`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_REVIEW_SHOW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_REVIEW_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/review/update`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_REVIEW_UPDATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CHAMBER_REVIEWS(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/review/get`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_REVIEWS", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
