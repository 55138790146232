import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface DashboardData {
  id: number;
  name: string;
  image: string;
}

export interface StoreInfo {
  DashboardData: DashboardData;
  profileData: DashboardData;
  imageData: DashboardData;
  errors: unknown;
}

@Module
export default class DashboardModule extends VuexModule implements StoreInfo {
  DashboardData = {} as DashboardData;
  profileData = {} as DashboardData;
  imageData = {} as DashboardData;
  errors = {};

  get getSolicitorDashboardData() {
    return this.DashboardData;
  }

  get solicitorProfileUpdate() {
    return this.profileData;
  }

  get getSolicitorDashboardImageData() {
    return this.imageData;
  }

  get solicitorFlowLoginErrors() {
    return this.errors;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Mutation
  SET_SOLICITOR_DASHBOARD(data) {
    this.DashboardData = data;
  }

  @Mutation
  SET_SOLICITOR_DASHBOARD_IMAGE_UPLOAD(data) {
    this.imageData = data;
  }

  @Mutation
  SET_SOLICITOR_PROFILE_UPDATE(data) {
    this.profileData = data;
  }

  @Action
  GET_SOLICITOR_DASHBOARD(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/dashbaord/get`, payload)
      .then((response) => {
        this.context.commit("SET_SOLICITOR_DASHBOARD", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_PROFILE_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/dashbaord/update`, payload)
      .then((response) => {
        this.context.commit("SET_SOLICITOR_PROFILE_UPDATE", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_DASHBOARD_IMAGE_UPLOAD(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/solicitor/dashbaord/update-image`,
      payload
    )
      .then((response) => {
        this.context.commit(
          "SET_SOLICITOR_DASHBOARD_IMAGE_UPLOAD",
          response.data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
