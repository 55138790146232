import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/website/Layout.vue"),
    children: [
      {
        path: "/",
        name: "main",
        component: () => import("@/views/apps/website/index.vue"),
        meta: {
          pageTitle: "Home",
          auth: false,
        },
      },
      {
        path: "/how-it-works",
        name: "How It Works",
        component: () => import("@/views/apps/website/how-it-works/index.vue"),
        meta: {
          pageTitle: "How It Works",
          auth: false,
        },
      },
      {
        path: "/contact-us",
        name: "Contact Us",
        component: () => import("@/views/apps/website/contact-us/index.vue"),
        meta: {
          pageTitle: "Contact Us",
          auth: false,
        },
      },
      {
        path: "/family-law-barristers",
        name: "Family Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/family-law/index.vue"),
        meta: {
          pageTitle: "Family Law Barristers",
          auth: false,
          title: "Home Page - Example App",
          metaTags: [
            {
              name: "description",
              content: "The home page of our example app.",
            },
            {
              property: "og:description",
              content: "The home page of our example app.",
            },
          ],
        },
      },
      {
        path: "/contract-law-barristers",
        name: "Contract Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/contract-law/index.vue"),
        meta: {
          pageTitle: "Contract Law Barristers",
          auth: false,
        },
      },
      {
        path: "/divorce-and-matrimonial-law-barristers",
        name: "Divorce and matrimonial Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/divorce-law/index.vue"),
        meta: {
          pageTitle: "Divorce and matrimonial Law Barristers",
          auth: false,
        },
      },
      {
        path: "/education-law-barristers",
        name: "Eucation Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/education-law/index.vue"),
        meta: {
          pageTitle: "Eucation Law Barristers",
          auth: false,
        },
      },
      {
        path: "/finance-law-barristers",
        name: "Finance Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/finance-law/index.vue"),
        meta: {
          pageTitle: "Finance Law Barristers",
          auth: false,
        },
      },
      {
        path: "/employment-law-barristers",
        name: "employment Law Barristers",
        component: () =>
          import("@/views/apps/website/landing/employment-law/index.vue"),
        meta: {
          pageTitle: "Employment Law Barristers",
          auth: false,
        },
      },
      {
        path: "/insolvency-and-bankruptcy-law-barristers",
        name: "insolvency-and-bankruptcy-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/insolvency-law/index.vue"),
        meta: {
          pageTitle: "Insolvency and Bankruptcy Law Barristers  ",
          auth: false,
        },
      },
      {
        path: "/landlord-and-tenant-law-barristers",
        name: "landlord-and-tenant-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/landlord-law/index.vue"),
        meta: {
          pageTitle: "Landlord and Tenant Law Barristers ",
          auth: false,
        },
      },
      {
        path: "/probate,-inheritance-and-trusts-law-barristers",
        name: "probate,-inheritance-and-trusts-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/probate-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/property-law-barristers",
        name: "property-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/property-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/agriculture-and-rural-affairs-barristers",
        name: "agriculture-and-rural-affairs-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/agriculture-and-rural-affairs-barristers/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/community-care-law-barristers",
        name: "community-care-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/community-care-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/company-and-partnership-law-barristers",
        name: "company-and-partnership-law-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/company-and-partnership/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/court-of-protection-barristers",
        name: "court-of-protection-barristers",
        component: () =>
          import("@/views/apps/website/landing/court-protection/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/criminal-law-barristers",
        name: "criminal-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/criminal-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/immigration-law-barristers",
        name: "immigration-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/immigration-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/international-human-rights-barristers",
        name: "international-human-rights-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/international-human-rights/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/local-government-barristers",
        name: "local-government-barristers",
        component: () =>
          import("@/views/apps/website/landing/local-government/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/personal-injury-and-clinical-negligence-barristers",
        name: "personal-injury-and-clinical-negligence-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/personal-injury-and-clinical-negligence/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/planning-and-environmental-law-barristers",
        name: "planning-and-environmental-law-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/planning-and-environmental-law/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/prison-and-police-law-barristers",
        name: "prison-and-police-law-barristers",
        component: () =>
          import(
            "@/views/apps/website/landing/prison-and-police-law/index.vue"
          ),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/public-law-barristers",
        name: "public-law-barristers",
        component: () =>
          import("@/views/apps/website/landing/public-law/index.vue"),
        meta: {
          pageTitle: "Probate, Inheritance and Trusts Law Barristers   ",
          auth: false,
        },
      },
      {
        path: "/teaser",
        name: "teaser",
        component: () => import("@/views/apps/website/teaser.vue"),
        meta: {
          pageTitle: "Teaser",
          auth: false,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/main/admin",
        name: "main-admin",
        component: () => import("@/views/apps/panel-view/PanelView.vue"),
        meta: {
          pageTitle: "main",
          auth: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/chamber/clerk",
        name: "chamber-clerk",
        component: () => import("@/views/apps/chambers-clerk/index.vue"),
        meta: {
          pageTitle: "Chamber clerk",
          auth: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/solicitor/admin",
        name: "solicitor-admin",
        component: () => import("@/views/apps/solicitor-admin/index.vue"),
        meta: {
          pageTitle: "Solicitor Admin",
          auth: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/solicitor/staff",
        name: "solicitor-staff",
        component: () => import("@/views/apps/solicitor-staff/index.vue"),
        meta: {
          pageTitle: "Solicitor Staff",
          auth: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/chamber/admin",
        name: "chamber-admin",
        component: () => import("@/views/apps/chambers-view/ChambersView.vue"),
        meta: {
          pageTitle: "Dashboard",
          auth: true,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
          auth: false,
        },
      },

      {
        path: "/forgot/password",
        name: "forgot-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ForgotPassword.vue"
          ),
        meta: {
          pageTitle: "Forgot Password",
          auth: false,
        },
      },

      {
        path: "/password/reset/:token",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ResetPassword.vue"),
        meta: {
          pageTitle: "Password reset",
          auth: false,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/quick-sign-up",
        name: "quick-sign-up",
        component: () => import("@/views/apps/quick-sign-up/index.vue"),
        meta: {
          pageTitle: "Quick Sign Up",
          auth: false,
        },
      },
      {
        path: "/claim/verify/:token",
        name: "verify-email",
        component: () =>
          import("@/views/apps/claim-my-listing/verify-email/index.vue"),
        meta: {
          pageTitle: "Verify Email",
          auth: false,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        path: "/quick-signup",
        name: "quick-sign-up",
        component: () => import("@/views/apps/quick-signup/index.vue"),
        meta: {
          pageTitle: "Quick Sign Up",
          auth: false,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/website/Layout.vue"),
    children: [
      {
        path: "/:slug",
        name: "claim-my-listing",
        component: () => import("@/views/apps/claim-my-listing/index.vue"),
        meta: {
          pageTitle: "Claim My Listing",
          auth: false,
        },
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
          auth: false,
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
          auth: false,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // before page access check if page requires authentication
  if (to.meta.auth) {
    const data = JSON.parse(localStorage.getItem("user") || "{}");
    if (data.token) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
