import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface UpdateSolicitor {
  image: string;
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  postcode: string;
  county: string;
  website: string;
  email: string;
  phone: string;
  permissions: string;
  status: number;
  id: number;
}

export interface SolicitorList {
  id?: number;
  name?: string;
  status?: number;
  image?: string;
  email?: string;
  phone?: string;
  website?: string;
  stripe_customer_id?: number;
  address_1?: string;
  address_2?: string;
  city?: string;
  county?: string;
  postcode?: string;
  country?: string;
  user_count?: number;
}

export interface SolicitorInfo {
  errors: unknown;
  updateSolicitorData: UpdateSolicitor;
  solicitorData: ISolicitorTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ISolicitorTableData extends IBaseTable {
  data: Array<SolicitorList> | [];
}

@Module
export default class SolicitorModule
  extends VuexModule
  implements SolicitorInfo
{
  errors = {};
  updateSolicitorData = {} as UpdateSolicitor;
  solicitorData = {} as ISolicitorTableData;

  get getErrorsSolicitor() {
    return this.errors;
  }
  get updateSolicitorDataModal() {
    return this.updateSolicitorData;
  }

  get getAllSolicitorData() {
    return this.solicitorData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_UPDATE_SOLICITOR](data) {
    this.updateSolicitorData = data;
  }
  @Mutation
  [Mutations.SET_SOLICITOR_LIST](data) {
    this.solicitorData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.UPDATE_SOLICITOR](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/solicitor/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_UPDATE_SOLICITOR, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.SOLICITOR_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/solicitor/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_SOLICITOR_LIST, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
