import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface updateCase {
  status: unknown;
}

export interface LawAreaList {
  name: string;
  id: number;
  status: number;
  pivot?: PivotList;
}
export interface ChamberList {
  id: number;
  chamber_id: number;
  case_data_id: number;
}
export interface PivotList {
  case_data_id: number;
  law_area_id: number;
}
export interface CourtListData {
  id: number;
  name: string;
  pivot?: PivotCourtList;
  city?: CityCourtList;
}
export interface PivotCourtList {
  case_data_id: number;
  court_id: number;
}
export interface CityCourtList {
  id: number;
  name: string;
}
export interface SolicitorListData {
  id: number;
  name: string;
}
export interface UserListData {
  id: number;
  first_name: string;
  last_name: string;
}
export interface CasesList {
  id?: unknown;
  status?: unknown;
  contact_count: number;
  solicitor_id: number;
  court_start_date: string;
  court_end_date: string;
  created_at: string;
  experties: number;
  city: number;
  experties_name: string;
  funding_type: number;
  funding_type_name: string;
  law_area?: LawAreaList;
  all_chambers?: ChamberList;
  awarded_chamber?: ChamberList;
  court?: CourtListData;
  solicitor?: SolicitorListData;
  user?: UserListData;
}

export interface CaseInfo {
  errors: unknown;
  updateCaseData: updateCase;
  resendInviteCaseData: updateCase;
  casesData: ICasesTableData;
  caseShowData: CasesList;
  addCaseData: CasesList;
  awardData: CasesList;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ICasesTableData extends IBaseTable {
  data: Array<CasesList> | [];
}

@Module
export default class CasesModule extends VuexModule implements CaseInfo {
  errors = {};
  updateCaseData = {} as updateCase;
  resendInviteCaseData = {} as updateCase;
  casesData = {} as ICasesTableData;
  caseShowData = {} as CasesList;
  addCaseData = {} as CasesList;
  awardData = {} as CasesList;

  get getSolicitorCaseError() {
    return this.errors;
  }
  get solicitorCaseAdd() {
    return this.addCaseData;
  }
  get solicitorCaseResendInviteRequest() {
    return this.resendInviteCaseData;
  }
  get solicitorCaseUpdate() {
    return this.updateCaseData;
  }
  get getSolicitorCaseById() {
    return this.caseShowData;
  }

  get getSolicitorCaseList() {
    return this.casesData;
  }
  get getSolicitorCaseAward() {
    return this.awardData;
  }

  // create mutation
  @Mutation
  SET_SOLICITOR_CASE_CREATE(data) {
    this.addCaseData = data;
  }
  @Mutation
  SET_SOLICITOR_CASE_RESEND_INVITE(data) {
    this.resendInviteCaseData = data;
  }
  @Mutation
  SET_SOLICITOR_CASE_UPDATE(data) {
    this.updateCaseData = data;
  }
  @Mutation
  SET_SOLICITOR_CASES(data) {
    this.casesData = data;
  }

  @Mutation
  SET_SOLICITOR_CASE_SHOW(data) {
    this.caseShowData = data;
  }

  @Mutation
  SET_SOLICITOR_CASE_AWARD(data) {
    this.awardData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  SOLICITOR_CASE_RESEND_INVITE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/resend-invite`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_SOLICITOR_CASE_RESEND_INVITE",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_CASE_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/update`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_CASE_UPDATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_CASE_CREATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/create`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_CASE_CREATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_CASE_AWARD(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/award`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_CASE_AWARD", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_CASE_SHOW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/get-case-byid`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_CASE_SHOW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_SOLICITOR_CASES(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/case/get`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_CASES", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
