import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface addChamber {
  id?: number;
  name?: string;
  email?: string;
  address_1?: string;
  address_2?: string;
  website?: string;
  city?: string;
  county?: string;
  postcode?: string;
  area_of_law?: Array<LawArea>;
  courts?: Array<unknown>;
  phone?: string;
  registered_barristers?: number;
  status?: number;
}
export interface LawArea {
  name: string;
  id: number;
}
export interface CityList {
  name: string;
  id: number;
}
export interface ChamberDel {
  id: number;
}

export interface CircuitObjData {
  id: number;
  name: string;
  status: number;
}
export interface CourtArrData {
  id: number;
  name: string;
  checked?: boolean;
}
export interface CityCourt {
  id: number;
  name: string;
  status: number;
  checked?: boolean;
  circuit?: CircuitObjData;
  court?: CourtArrData;
}

export interface TreeListData {
  item: number;
}

export interface ChamberInfo {
  errors: unknown;
  addChambersData: addChamber;
  lawAreaData: Array<LawArea>;
  cityCourtData: Array<CityCourt>;
  cityListAllData: Array<CityList>;
  chambersData: IChamberTableData;
  chamberData: addChamber;
  updateChambersData: addChamber;
  deleteChamberData: ChamberDel;
  treeData: Array<TreeListData>;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IChamberTableData extends IBaseTable {
  data: Array<addChamber> | [];
}

@Module
export default class ChambersModule extends VuexModule implements ChamberInfo {
  errors = {};
  addChambersData = {} as addChamber;
  updateChambersData = {} as addChamber;
  chambersData = {} as IChamberTableData;
  lawAreaData = [] as Array<LawArea>;
  cityCourtData = [] as Array<CityCourt>;
  cityListAllData = [] as Array<CityList>;
  chamberData = {} as addChamber;
  deleteChamberData = {} as ChamberDel;
  treeData = [] as Array<TreeListData>;

  //
  get lawAreaList(): Array<LawArea> {
    return this.lawAreaData;
  }
  get cityListings(): Array<CityList> {
    return this.cityListAllData;
  }
  get cityCourtAllData(): Array<CityCourt> {
    return this.cityCourtData;
  }

  // error
  get getErrorsChamber() {
    return this.errors;
  }
  get getAllChambers() {
    return this.chambersData;
  }
  get getChamberData() {
    return this.addChambersData;
  }
  get getChamberByIdData() {
    return this.chamberData;
  }
  get updateChamberFormData() {
    return this.updateChambersData;
  }
  get deleteChamberDataModal() {
    return this.deleteChamberData;
  }
  get getAreaTreeData() {
    return this.treeData;
  }

  // create mutation
  @Mutation
  [Mutations.ADD_CHAMBER_DATA](data) {
    this.addChambersData = data;
  }

  @Mutation
  [Mutations.AREA_OF_LAW_LIST](data) {
    this.lawAreaData = data;
  }
  @Mutation
  [Mutations.SET_ALL_CITY_COURT](data) {
    this.cityCourtData = data;
  }
  @Mutation
  [Mutations.CITY_LIST_DATA](data) {
    this.cityListAllData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.ALL_CHAMBER_DATA](data) {
    this.chambersData = data;
  }

  @Mutation
  [Mutations.SET_CHAMBER_BY_ID](data) {
    this.chamberData = data;
  }

  @Mutation
  [Mutations.SET_UPDATE_CHAMBER_DATA](data) {
    this.updateChambersData = data;
  }

  @Mutation
  [Mutations.SET_DELETE_CHAMBER](data) {
    this.deleteChamberData = data;
  }

  @Mutation
  [Mutations.AREA_TREE_DATA](data) {
    this.treeData = data;
  }

  // define action
  @Action
  [Actions.ADD_CHAMBER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/chamber/create`, payload)
      .then((response) => {
        this.context.commit(Mutations.ADD_CHAMBER_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.AREA_OF_LAW](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/area-of-law/get`, payload)
      .then((response) => {
        this.context.commit(
          Mutations.AREA_OF_LAW_LIST,
          response.data.data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.ALL_CITY_COURT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/get-all-city-court`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ALL_CITY_COURT, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CITY_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.CITY_LIST_DATA, response.data.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.ALL_CHAMBER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/chamber/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.ALL_CHAMBER_DATA, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_CHAMBER_BY_ID](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/chamber/show`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_CHAMBER_BY_ID, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_CHAMBER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/chamber/update`, payload)
      .then((response) => {
        this.context.commit(
          Mutations.SET_UPDATE_CHAMBER_DATA,
          response.data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_CHAMBER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/chamber/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_DELETE_CHAMBER, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
