import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";
import JwtService from "@/core/services/JwtService";
interface StoreInfo {
  errors: unknown;
  chamberCount: unknown;
  isUserExists: unknown;
  basicInfo: unknown;
  basicCaseInfo: unknown;
  paymentStatus: unknown;
  chambersList: unknown;
  caseItem: unknown;
  confirmCaseDetails: unknown;
  selectedChambersList: unknown;
  chamberContact: unknown;
}

@Module
export default class HappyPathModule extends VuexModule implements StoreInfo {
  errors = {};
  chamberCount = 0;
  isUserExists = false;
  basicInfo = {};
  basicCaseInfo = {};
  paymentStatus = {};
  chambersList = [];
  caseItem = [];
  confirmCaseDetails = [];
  selectedChambersList = [];
  chamberContact = {};

  get revealChamberContact() {
    return this.chamberContact;
  }

  @Mutation
  SET_CHAMBER_CONTACT(data) {
    this.chamberContact = data;
  }

  get getSelectedChambersList() {
    return this.selectedChambersList;
  }

  @Mutation
  SET_SELECTED_CHAMBERS(data) {
    this.selectedChambersList = data;
  }
  get getCaseConfirmDetails() {
    return this.confirmCaseDetails;
  }

  @Mutation
  SET_CONFIRM_CASE(data) {
    this.confirmCaseDetails = data;
  }

  get getCaseById() {
    return this.caseItem;
  }

  @Mutation
  SET_CASE_BY_ID(data) {
    this.caseItem = data;
  }

  get getChambersList() {
    return this.chambersList;
  }

  @Mutation
  SET_CHAMBER_LIST(data) {
    this.chambersList = data;
  }

  get getPaymentStatus() {
    return this.paymentStatus;
  }

  @Mutation
  SET_PAYMENT_STATUS(data) {
    this.paymentStatus = data;
  }

  get getBasicCaseInfo() {
    return this.basicCaseInfo;
  }

  @Mutation
  SET_STORE_BASIC_CASE_INFO(data) {
    this.basicCaseInfo = data;
  }

  get getBasicInfo() {
    return this.basicInfo;
  }

  @Mutation
  SET_STORE_BASIC_INFO(data) {
    JwtService.saveToken(data.data.token);
    this.basicInfo = data;
  }
  get getChamberCount() {
    return this.chamberCount;
  }

  @Mutation
  SET_CHAMBER_COUNT(data) {
    this.chamberCount = data;
  }

  get getStatusOfUserExists() {
    return this.isUserExists;
  }

  @Mutation
  SET_CHECK_USER_EMAIL(data) {
    if (data.length === 0) {
      this.isUserExists = false;
    } else if (data && Object.keys(data).length > 0) {
      this.isUserExists = true;
    }
  }

  get getHappypathError() {
    return this.errors;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = error;
  }

  @Mutation
  SET_HAPPY_PATH_OBJECT(data) {
    const obj = JSON.parse(localStorage.getItem("happy_path") || "{}");
    localStorage.setItem("happy_path", JSON.stringify({ ...obj, ...data }));
  }

  @Action
  GET_CHAMBER_COUNT(payload) {
    ApiService.post(`${BASE_URL}/solicitor/new-case/get-chamber-count`, payload)
      .then((response) => {
        this.context.commit("SET_CHAMBER_COUNT", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHECK_USER_EMAIL(payload) {
    ApiService.post(`${BASE_URL}/solicitor/new-case/check-email`, payload)
      .then((response) => {
        this.context.commit("SET_CHECK_USER_EMAIL", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  STORE_BASIC_INFO(payload) {
    ApiService.post(
      `${BASE_URL}/solicitor/new-case/store-basic-user-info`,
      payload
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          this.context.commit("SET_STORE_BASIC_INFO", response.data);
        } else {
          this.context.dispatch("SET_TOAST", response.data);
          this.context.commit("SET_ERROR", response.data.message);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
  @Action
  STORE_BASIC_CASE_INFO(payload) {
    ApiService.setHeader();
    ApiService.post(
      `${BASE_URL}/solicitor/new-case/store-basic-case-info`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_HAPPY_PATH_OBJECT", {
            caseId: response.data.data.id,
          });
          this.context.commit("SET_STORE_BASIC_CASE_INFO", response.data.data);
        } else {
          this.context.dispatch("SET_TOAST", response.data);
          this.context.commit("SET_ERROR", response.data.data.message);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  MAKE_PAYMENT(payload) {
    ApiService.setHeader();
    ApiService.post(`${BASE_URL}/solicitor/payment/make`, payload)
      .then((response) => {
        this.context.commit("SET_PAYMENT_STATUS", response.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CHAMBER_LIST(payload) {
    ApiService.setHeader();
    ApiService.post(`${BASE_URL}/solicitor/new-case/get-chamber-list`, payload)
      .then((response) => {
        this.context.commit("SET_CHAMBER_LIST", response.data.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CASE_BY_ID(payload) {
    ApiService.setHeader();
    ApiService.post(`${BASE_URL}/solicitor/new-case/get-case-byid`, payload)
      .then((response) => {
        this.context.commit("SET_CASE_BY_ID", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CONFIRM_CASE(payload) {
    ApiService.setHeader();
    ApiService.post(`${BASE_URL}/solicitor/new-case/confirm-case`, payload)
      .then((response) => {
        this.context.commit("SET_CONFIRM_CASE", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_SELECTED_CHAMBERS(payload) {
    ApiService.setHeader();
    ApiService.post(
      `${BASE_URL}/solicitor/new-case/get-selected-chambers`,
      payload
    )
      .then((response) => {
        this.context.commit("SET_SELECTED_CHAMBERS", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CHAMBER_CONTACT(payload) {
    ApiService.setHeader();
    ApiService.post(
      `${BASE_URL}/solicitor/new-case/get-chamber-contact`,
      payload
    )
      .then((response) => {
        this.context.commit("SET_CHAMBER_CONTACT", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
