import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";
interface StoreInfo {
  areasOfLawList: unknown;
  cityList: unknown;
  circuitList: unknown;
  courtList: unknown;
  errors: unknown;
  contactUsData: unknown;
  chamberData: chamberDataList;
  chamberById: chamberList;
  otpDetails: OtpList;
  verifyOtpDetails: OtpList;
  totalChamberCount: number;
}

export interface OtpList {
  id: number;
  chamber_id: number;
  service_sid: string;
  account_sid: string;
  sid: string;
  name?: string;
  slug?: string;
}

export interface chamberList {
  id: number;
  name: string;
  slug: string;
}

export interface chamberDataList {
  data: Array<chamberList> | [];
}

@Module
export default class MetaDataModule extends VuexModule implements StoreInfo {
  areasOfLawList = [];
  totalChamberCount = 0;
  cityList = [];
  circuitList = [];
  courtList = [];
  errors = {};
  contactUsData = {};
  chamberData = {} as chamberDataList;
  chamberById = {} as chamberList;
  otpDetails = {} as OtpList;
  verifyOtpDetails = {} as OtpList;

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  /*Areas of law meta data*/

  get getListOfAreasOfLaw() {
    return this.areasOfLawList;
  }

  @Mutation
  SET_PUBLIC_AREA_OF_LAW_LIST(data) {
    this.areasOfLawList = data;
  }

  @Action
  PUBLIC_AREA_OF_LAW_LIST(payload) {
    ApiService.post(`${BASE_URL}/public/area-of-law/get`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_AREA_OF_LAW_LIST", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  /*City meta data*/

  get getListOfCities() {
    return this.cityList;
  }

  @Mutation
  SET_PUBLIC_CITY_LIST(data) {
    this.cityList = data;
  }

  @Action
  PUBLIC_CITY_LIST(payload) {
    ApiService.post(`${BASE_URL}/public/city/get`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_CITY_LIST", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  /*Circuit list meta data*/

  get getListOfCircuits() {
    return this.circuitList;
  }

  @Mutation
  SET_PUBLIC_CIRCUIT_LIST(data) {
    this.circuitList = data;
  }

  @Action
  PUBLIC_CIRCUIT_LIST(payload) {
    ApiService.post(`${BASE_URL}/public/circuit/get`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_CIRCUIT_LIST", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  /*Courts list meta data*/

  get getListOfCourts() {
    return this.courtList;
  }

  @Mutation
  SET_PUBLIC_COURT_LIST(data) {
    this.courtList = data;
  }

  @Action
  PUBLIC_COURT_LIST(payload) {
    ApiService.post(`${BASE_URL}/public/court/get`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_COURT_LIST", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  /*Courts list meta data*/

  get getListOfChambers() {
    return this.chamberData;
  }

  @Mutation
  SET_PUBLIC_CHAMBER_LIST(data) {
    this.chamberData = data;
  }

  @Action
  PUBLIC_CHAMBER_LIST(payload) {
    ApiService.post(`${BASE_URL}/public/chamber/get`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_CHAMBER_LIST", response.data.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  /*Courts list meta data*/

  get getPublicChamberById() {
    return this.chamberById;
  }

  @Mutation
  SET_PUBLIC_CHAMBER_SHOW(data) {
    this.chamberById = data;
  }

  @Action
  PUBLIC_CHAMBER_SHOW(payload) {
    ApiService.post(`${BASE_URL}/public/chamber/show`, payload)
      .then((response) => {
        this.context.commit("SET_PUBLIC_CHAMBER_SHOW", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  get getOtpDetails() {
    return this.otpDetails;
  }

  @Mutation
  SET_GENERATE_OTP(data) {
    this.otpDetails = data;
  }

  @Action
  GENERATE_OTP(payload) {
    ApiService.post(`${BASE_URL}/public/chamber/generate-otp`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_GENERATE_OTP", response.data);
        } else {
          this.context.dispatch("SET_TOAST", response.data);
          this.context.commit("SET_ERROR", response.data.message);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);

        this.context.dispatch("SET_TOAST", response.data);
      });
  }

  get getVerifiedOtpDetails() {
    return this.verifyOtpDetails;
  }

  @Mutation
  SET_VERIFY_OTP(data) {
    this.verifyOtpDetails = data;
  }

  @Action
  VERIFY_OTP(payload) {
    ApiService.post(`${BASE_URL}/public/chamber/verify-otp`, payload)
      .then((response) => {
        this.context.commit("SET_VERIFY_OTP", response.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  get getTotalChamberCount() {
    return this.totalChamberCount;
  }

  @Mutation
  SET_TOTAL_CHAMBERS_COUNT(data) {
    this.totalChamberCount = data;
  }

  @Action
  TOTAL_CHAMBERS_COUNT(payload) {
    ApiService.post(`${BASE_URL}/public/chamber/get-count`, payload)
      .then((response) => {
        this.context.commit("SET_TOTAL_CHAMBERS_COUNT", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  get contactUsInfo() {
    return this.contactUsData;
  }

  @Mutation
  SET_CONTACT_US(data) {
    this.contactUsData = data;
  }

  @Action
  CONTACT_US(payload) {
    ApiService.post(`${BASE_URL}/public/contact-us`, payload)
      .then((response) => {
        this.context.commit("SET_CONTACT_US", response.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
