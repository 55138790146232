import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface updateCase {
  status: unknown;
}

export interface LawAreaList {
  name: string;
  id: number;
  status: number;
  pivot?: PivotList;
}
export interface PivotList {
  case_data_id: number;
  law_area_id: number;
}
export interface CourtListData {
  id: number;
  name: string;
  pivot?: PivotCourtList;
  city?: CityCourtList;
}
export interface PivotCourtList {
  case_data_id: number;
  court_id: number;
}
export interface CityCourtList {
  id: number;
  name: string;
}
export interface SolicitorListData {
  id: number;
  name: string;
}
export interface UserListData {
  id: number;
  first_name: string;
  last_name: string;
}
export interface CasesList {
  id: number;
  contact_count: number;
  solicitor_id: number;
  court_start_date: string;
  court_end_date: string;
  created_at: string;
  experties: number;
  experties_name: string;
  funding_type: number;
  funding_type_name: string;
  law_area?: LawAreaList;
  court?: CourtListData;
  solicitor?: SolicitorListData;
  user?: UserListData;
}

export interface CaseInfo {
  errors: unknown;
  updateCaseData: updateCase;
  casesData: ICasesTableData;
  caseShowData: CasesList;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ICasesTableData extends IBaseTable {
  data: Array<CasesList> | [];
}

@Module
export default class CasesModule extends VuexModule implements CaseInfo {
  errors = {};
  updateCaseData = {} as updateCase;
  casesData = {} as ICasesTableData;
  caseShowData = {} as CasesList;

  get getChamberCaseError() {
    return this.errors;
  }
  get chamberCaseUpdate() {
    return this.updateCaseData;
  }
  get getChamberCaseById() {
    return this.caseShowData;
  }

  get getChamberCaseList() {
    return this.casesData;
  }

  // create mutation
  @Mutation
  SET_CHAMBER_CASE_UPDATE(data) {
    this.updateCaseData = data;
  }
  @Mutation
  SET_CHAMBER_CASES(data) {
    this.casesData = data;
  }

  @Mutation
  SET_CHAMBER_CASE_SHOW(data) {
    this.caseShowData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  CHAMBER_CASE_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/case/complete-case`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_CASE_UPDATE", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_CASE_SHOW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/case/get-case-by-id`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_CASE_SHOW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_CHAMBER_CASES(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/case/get-case`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_CHAMBER_CASES", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
