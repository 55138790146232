import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface DashboardData {
  id: number;
  name: string;
  image: string;
}

export interface addChamber {
  id?: number;
  name?: string;
  email?: string;
  address_1?: string;
  address_2?: string;
  website?: string;
  city?: string;
  county?: string;
  postcode?: string;
  area_of_law?: Array<LawArea>;
  courts?: Array<unknown>;
  phone?: string;
  registered_barristers?: number;
  status?: number;
}

export interface LawArea {
  name: string;
  id: number;
}

export interface StoreInfo {
  DashboardData: DashboardData;
  imageData: DashboardData;
  errors: unknown;
  updateChambersData: addChamber;
}

@Module
export default class DashboardModule extends VuexModule implements StoreInfo {
  DashboardData = {} as DashboardData;
  imageData = {} as DashboardData;
  updateChambersData = {} as addChamber;
  errors = {};

  get getChamberDashboardData() {
    return this.DashboardData;
  }

  get getChamberDashboardImageData() {
    return this.imageData;
  }

  get chamberFlowLoginErrors() {
    return this.errors;
  }

  get updateChamberProfileData() {
    return this.updateChambersData;
  }

  @Mutation
  SET_CHAMBER_PROFILE_UPDATE(data) {
    this.updateChambersData = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Mutation
  SET_CHAMBER_DASHBOARD(data) {
    this.DashboardData = data;
  }

  @Mutation
  SET_CHAMBER_DASHBOARD_IMAGE_UPLOAD(data) {
    this.imageData = data;
  }

  @Action
  GET_CHAMBER_DASHBOARD(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/dashbaord/get`, payload)
      .then((response) => {
        this.context.commit("SET_CHAMBER_DASHBOARD", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_PROFILE_UPDATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/chamber/dashbaord/update`, payload)
      .then((response) => {
        this.context.commit("SET_CHAMBER_PROFILE_UPDATE", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  CHAMBER_DASHBOARD_IMAGE_UPLOAD(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/chamber/dashbaord/update-image`,
      payload
    )
      .then((response) => {
        this.context.commit(
          "SET_CHAMBER_DASHBOARD_IMAGE_UPLOAD",
          response.data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
