import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface ReviewList {
  name: string;
  status_name: string;
  status: string;
  id: number;
}

export interface ReviewDel {
  id: number;
}

export interface ReviewInfo {
  errors: unknown;
  updateReviewData: ReviewList;
  deleteReviewData: ReviewDel;
  reviewData: IReviewTableData;
  reviewShowData: ReviewList;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IReviewTableData extends IBaseTable {
  data: Array<ReviewList> | [];
}

@Module
export default class ReviewModule extends VuexModule implements ReviewInfo {
  errors = {};
  updateReviewData = {} as ReviewList;
  deleteReviewData = {} as ReviewDel;
  reviewData = {} as IReviewTableData;
  reviewShowData = {} as ReviewList;

  get getErrorsReview() {
    return this.errors;
  }

  get updateReviewDataModal() {
    return this.updateReviewData;
  }
  get deleteReviewDataModal() {
    return this.deleteReviewData;
  }
  get getReviewDataById() {
    return this.reviewShowData;
  }
  get getAllReviewData() {
    return this.reviewData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_UPDATE_REVIEW](data) {
    this.updateReviewData = data;
  }
  @Mutation
  [Mutations.SET_DELETE_REVIEW](data) {
    this.deleteReviewData = data;
  }
  @Mutation
  [Mutations.SET_REVIEW_LIST](data) {
    this.reviewData = data;
  }

  @Mutation
  [Mutations.SET_SHOW_REVIEW](data) {
    this.reviewShowData = data;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.UPDATE_REVIEW](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/review/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_UPDATE_REVIEW, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_REVIEW](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/review/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_DELETE_REVIEW, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.SHOW_REVIEW](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/review/show`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_SHOW_REVIEW, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.REVIEW_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/review/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_REVIEW_LIST, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
