import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface ListItem {
  id: number;
  name: string;
  slug: string;
}

export interface StoreInfo {
  errors: unknown;
  SignupAndClaimData: ListItem;
}

@Module
export default class SolicitorModule extends VuexModule implements StoreInfo {
  SignupAndClaimData = {} as ListItem;
  errors = {};

  get getSolicitorSignupError() {
    return this.errors;
  }

  get getSolicitorSignupAndClaim() {
    return this.SignupAndClaimData;
  }

  @Mutation
  SET_SOLICITOR_QUICK_SIGNUP(data) {
    const obj = JSON.parse(
      localStorage.getItem("solicitor_signup_data") || "{}"
    );
    localStorage.setItem(
      "solicitor_signup_data",
      JSON.stringify({ ...obj, ...data })
    );
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Mutation
  SET_SOLICITOR_SIGNUP_AND_CLAIM(data) {
    this.SignupAndClaimData = data;
  }

  @Action
  SOLICITOR_SIGNUP_AND_CLAIM(payload) {
    ApiService.setHeader();
    return ApiService.post(
      `${BASE_URL}/public/sign-up/solicitor/store-basic-info`,
      payload
    )
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_SOLICITOR_SIGNUP_AND_CLAIM",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
