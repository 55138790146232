enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  GET_CUSTOMERS = "getCustomers",
  AREA_OF_LAW = "areaOfLaw",
  CITY_LIST = "cityList",
  ADD_COURT = "addCourt",
  COURT_LIST = "courtList",
  UPDATE_COURT = "updateCourt",
  DELETE_COURT = "deleteCourt",
  ADD_CHAMBER = "addChamber",
  UPDATE_CHAMBER = "updateChamber",
  DELETE_CHAMBER = "deleteChamber",
  ALL_CHAMBER = "allChamber",
  GET_CHAMBER_BY_ID = "getChamberById",
  ADD_REGION = "addRegion",
  REGION_LIST = "regionList",
  UPDATE_REGION = "updateRegion",
  DELETE_REGION = "deleteRegion",
  ADD_USER = "addUser",
  USER_LIST = "userList",
  DELETE_USER = "deleteUser",
  UPDATE_USER_DATA = "updateUserData",
  ADD_CIRCUIT = "addCircuit",
  CIRCUIT_LIST = "circuitList",
  UPDATE_CIRCUIT = "updateCircuit",
  DELETE_CIRCUIT = "deleteCircuit",
  CIRCUIT_LIST_OPTION = "circuitListOption",
  ALL_CITY_COURT = "allCityCourt",
  SOLICITOR_LIST = "solicitorList",
  UPDATE_SOLICITOR = "updateSolicitor",
  ENQUIRY_LIST = "enquiryList",
  UPDATE_ENQUIRY = "updateEnquiry",
  SHOW_ENQUIRY = "showEnquiry",
  CASE_LIST = "caseList",
  SHOW_CASE = "showCase",
  UPDATE_CASE = "updateCase",
  REVIEW_LIST = "reviewList",
  SHOW_REVIEW = "showReview",
  UPDATE_REVIEW = "updateReview",
  DELETE_REVIEW = "deleteReview",
  BILING_LIST = "bilingList",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_CUSTOMERS = "setCustomers",
  AREA_OF_LAW_LIST = "areaOfLawList",
  CITY_LIST_DATA = "cityListData",
  ADD_COURT_DATA = "addCourtData",
  COURT_LIST_DATA = "courtListData",
  UPDATE_COURT_DATA = "updateCourtData",
  DELETE_COURT_DATA = "deleteCourtData",
  ADD_CHAMBER_DATA = "addChamberData",
  SET_UPDATE_CHAMBER_DATA = "setUpdateChamberData",
  SET_DELETE_CHAMBER = "setDeleteChamber",
  ALL_CHAMBER_DATA = "allChamberData",
  SET_CHAMBER_BY_ID = "setChamberById",
  ADD_REGION_DATA = "addRegionData",
  REGION_LIST_DATA = "regionListData",
  UPDATE_REGION_DATA = "updateRegionData",
  DELETE_REGION_DATA = "deleteRegionData",
  SET_ADD_USER = "setAddUser",
  SET_USER_LIST_DATA = "setUserListData",
  SET_UPDATE_USER_DATA = "setUpdateUserData",
  SET_DELETE_USER = "setDeleteUser",
  SET_ADD_CIRCUIT = "setAddCircuit",
  SET_CIRCUIT_LIST = "setCircuitList",
  SET_UPDATE_CIRCUIT = "setUpdateCircuit",
  SET_DELETE_CIRCUIT = "setDeleteCircuit",
  SET_CIRCUIT_LIST_OPTION = "setCircuitListOption",
  SET_ALL_CITY_COURT = "setAllCityCourt",
  SET_SOLICITOR_LIST = "setSolicitorList",
  SET_UPDATE_SOLICITOR = "setUpdateSolicitor",
  SET_ENQUIRY_LIST = "setEnquiryList",
  SET_UPDATE_ENQUIRY = "setUpdateEnquiry",
  SET_SHOW_ENQUIRY = "setShowEnquiry",
  AREA_TREE_DATA = "areaTreeData",
  SET_CASE_LIST = "setCaseList",
  SET_SHOW_CASE = "setShowCase",
  SET_UPDATE_CASE = "setUpdateCaseData",
  SET_REVIEW_LIST = "setReviewList",
  SET_SHOW_REVIEW = "setShowReview",
  SET_UPDATE_REVIEW = "setUpdateReview",
  SET_DELETE_REVIEW = "setDeleteReview",
  SET_BILING_LIST = "setBilingList",
}

export { Actions, Mutations };
