
import { defineComponent, watch, computed, ref } from "vue";
import { Toast } from "bootstrap";
import { useStore } from "vuex";
export default defineComponent({
  name: "toast-comp",
  setup() {
    const toaster = ref(null);
    const store = useStore();
    const toastState = computed(() => {
      return store.getters.getToasterState;
    });
    const submitHandler = () => {
      const toast = new Toast(toaster.value);
      toast.show();
    };

    watch(
      () => toastState.value,
      (newVal) => {
        if (newVal) submitHandler();
      }
    );
    return {
      toastState,
      toaster,
      submitHandler,
    };
  },
});
