import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface addCircuit {
  name: string;
  status: number;
}

export interface CircuitList {
  name: string;
  status: number;
  region_count: number;
  id: number;
}

export interface CircutDel {
  id: number;
}

export interface CircuitInfo {
  errors: unknown;
  addCircuitData: addCircuit;
  updateCircuitData: CircuitList;
  deleteCircuitData: CircutDel;
  circuitData: ICircuitTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ICircuitTableData extends IBaseTable {
  data: Array<CircuitList> | [];
}

@Module
export default class CircuitModule extends VuexModule implements CircuitInfo {
  errors = {};
  addCircuitData = {} as addCircuit;
  updateCircuitData = {} as CircuitList;
  deleteCircuitData = {} as CircutDel;
  circuitData = {} as ICircuitTableData;

  get getErrorsCircuit() {
    return this.errors;
  }
  get addCircuitDataModal() {
    return this.addCircuitData;
  }
  get updateCircuitDataModal() {
    return this.updateCircuitData;
  }
  get deleteCircuitDataModal() {
    return this.deleteCircuitData;
  }

  get getAllCircuitData() {
    return this.circuitData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_ADD_CIRCUIT](data) {
    this.addCircuitData = data;
  }
  @Mutation
  [Mutations.SET_UPDATE_CIRCUIT](data) {
    this.updateCircuitData = data;
  }
  @Mutation
  [Mutations.SET_DELETE_CIRCUIT](data) {
    this.deleteCircuitData = data;
  }
  @Mutation
  [Mutations.SET_CIRCUIT_LIST](data) {
    this.circuitData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.ADD_CIRCUIT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/circuit/create`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ADD_CIRCUIT, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_CIRCUIT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/circuit/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_UPDATE_CIRCUIT, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_CIRCUIT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/circuit/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_DELETE_CIRCUIT, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CIRCUIT_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/circuit/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_CIRCUIT_LIST, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
