import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface ToasterInfo {
  show: boolean;
  message: string;
  error: boolean;
}
interface StoreInfo {
  data: ToasterInfo;
}

@Module
export default class Notification extends VuexModule implements StoreInfo {
  data = {} as ToasterInfo;

  get getToasterState() {
    return this.data;
  }

  @Mutation
  UPDATE_TOAST(data) {
    this.data = data;
  }

  @Action
  SET_TOAST(payload) {
    this.context.commit("UPDATE_TOAST", payload);
  }
}
