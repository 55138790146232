import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface addRegion {
  name: string;
  status: number;
  circuit_id: number;
}

export interface CircuitData {
  name: string;
  id: number;
}
export interface RegionList {
  name: string;
  status: number;
  id: number;
  circuit: CircuitData;
}

export interface RegionDel {
  id: number;
}

export interface CircuitList {
  id: number;
  name: string;
}

export interface RegionInfo {
  errors: unknown;
  addRegionsData: addRegion;
  circuitListAllData: Array<CircuitList>;
  updateRegionsData: RegionList;
  deleteRegionsData: RegionDel;
  regionData: IRegionTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IRegionTableData extends IBaseTable {
  data: Array<RegionList> | [];
}

@Module
export default class RegionModule extends VuexModule implements RegionInfo {
  errors = {};
  addRegionsData = {} as addRegion;
  updateRegionsData = {} as RegionList;
  deleteRegionsData = {} as RegionDel;
  regionData = {} as IRegionTableData;
  circuitListAllData = [] as Array<CircuitList>;

  get circuitListings(): Array<CircuitList> {
    return this.circuitListAllData;
  }

  get getErrorsRegion() {
    return this.errors;
  }
  get addRegionDataModal() {
    return this.addRegionsData;
  }
  get updateRegionDataModal() {
    return this.updateRegionsData;
  }
  get deleteRegionDataModal() {
    return this.deleteRegionsData;
  }

  get getAllRegionData() {
    return this.regionData;
  }

  // create mutation
  @Mutation
  [Mutations.ADD_REGION_DATA](data) {
    this.addRegionsData = data;
  }
  @Mutation
  [Mutations.UPDATE_REGION_DATA](data) {
    this.updateRegionsData = data;
  }
  @Mutation
  [Mutations.DELETE_REGION_DATA](data) {
    this.deleteRegionsData = data;
  }
  @Mutation
  [Mutations.REGION_LIST_DATA](data) {
    this.regionData = data;
  }
  @Mutation
  [Mutations.SET_CIRCUIT_LIST_OPTION](data) {
    this.circuitListAllData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.ADD_REGION](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/create`, payload)
      .then((response) => {
        this.context.commit(Mutations.ADD_REGION_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_REGION](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.UPDATE_REGION_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_REGION](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.DELETE_REGION_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.REGION_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.REGION_LIST_DATA, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CIRCUIT_LIST_OPTION](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/circuit/get`, payload)
      .then((response) => {
        this.context.commit(
          Mutations.SET_CIRCUIT_LIST_OPTION,
          response.data.data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
