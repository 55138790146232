import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";
interface StoreInfo {
  addressList: unknown;
  addressDetails: unknown;
  errors: unknown;
}

@Module
export default class GetAddressModule extends VuexModule implements StoreInfo {
  addressList = [];
  addressDetails = [];
  errors = {};

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  get getSelectedAddressDetails() {
    return this.addressDetails;
  }

  @Mutation
  SET_ADDRESS_DETAILS(data) {
    this.addressDetails = data;
  }

  get getPostcodeAddressList() {
    return this.addressList;
  }

  @Mutation
  SET_ADDRESS_FROM_POSTCODE(data) {
    this.addressList = data;
  }

  @Action
  GET_ADDRESS_FROM_POSTCODE(payload) {
    ApiService.setHeader();
    ApiService.post(
      `${BASE_URL}/solicitor/new-case/get-address-by-postcode`,
      payload
    )
      .then((response) => {
        this.context.commit("SET_ADDRESS_FROM_POSTCODE", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  GET_ADDRESS_DETAILS(payload) {
    ApiService.setHeader();
    ApiService.post(`${BASE_URL}/solicitor/new-case/get-address-by-id`, payload)
      .then((response) => {
        this.context.commit("SET_ADDRESS_DETAILS", response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
