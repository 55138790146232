import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "@/store/BaseUrls";

export interface ReviewableList {
  id: number;
  name: string;
  status_name: string;
  plan_name: string;
}
export interface SourcableList {
  id: number;
  name: string;
  slug: string;
  plan_name: string;
}
export interface ReviewList {
  id: number;
  rating: number;
  case_data_id: number;
  reviewable_id: number;
  sourceable_id: number;
  status: number;
  comment: string;
  created_at: string;
  reviewable_type: string;
  sourceable_type: string;
  created_date: string;
  reviewable?: ReviewableList;
  sourcable?: SourcableList;
}

export interface StoreInfo {
  errors: unknown;
  reviewData: ReviewTableData;
  reviewShowData: ReviewList;
  ReviewCreate: ReviewCreate;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ReviewTableData extends IBaseTable {
  data: Array<ReviewList> | [];
}

export interface ReviewCreate {
  case_data_id: number;
  solicitor_id: number;
  review_id: number;
  rating: number;
  comment: string;
}

@Module
export default class ReviewsModule extends VuexModule implements StoreInfo {
  errors = {};
  reviewData = {} as ReviewTableData;
  reviewShowData = {} as ReviewList;
  ReviewCreate = {} as ReviewCreate;

  get getSolicitorReviewError() {
    return this.errors;
  }
  get getSolicitorReviewList() {
    return this.reviewData;
  }

  get solicitorReviewCreate() {
    return this.ReviewCreate;
  }

  get getSolicitorReviewById() {
    return this.reviewShowData;
  }

  @Mutation
  SET_SOLICITOR_REVIEWS(data) {
    this.reviewData = data;
  }

  @Mutation
  SET_SOLICITOR_REVIEW_SHOW(data) {
    this.reviewShowData = data;
  }

  @Mutation
  SET_SOLICITOR_REVIEW_CREATE(data) {
    this.ReviewCreate = data;
  }

  @Mutation
  SET_ERROR(error) {
    this.errors = { ...error };
  }

  @Action
  SOLICITOR_REVIEW_SHOW(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/review/show`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_REVIEW_SHOW", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  SOLICITOR_REVIEW_CREATE(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/review/create`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit(
            "SET_SOLICITOR_REVIEW_CREATE",
            response.data.data
          );
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }

  @Action
  GET_SOLICITOR_REVIEWS(payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/solicitor/review/get`, payload)
      .then((response) => {
        if (response.data.result) {
          this.context.commit("SET_SOLICITOR_REVIEWS", response.data.data);
        } else {
          this.context.commit("SET_ERROR", response.data);
        }
      })
      .catch(({ response }) => {
        this.context.commit("SET_ERROR", response.data);
      });
  }
}
