import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface addCourt {
  name: string;
  city_id: number;
  status: number;
  court_type: number;
}

export interface CityDetail {
  name: string;
}
export interface CourtList {
  name: string;
  city_id: number;
  status: number;
  id: number;
  city: CityDetail;
  court_type: number;
  court_type_name: string;
}

export interface CourtDel {
  id: number;
}

export interface CourtInfo {
  errors: unknown;
  addCourtsData: addCourt;
  updateCourtsData: CourtList;
  deleteCourtsData: CourtDel;
  courtData: ICourtTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface ICourtTableData extends IBaseTable {
  data: Array<CourtList> | [];
}

@Module
export default class CourtModule extends VuexModule implements CourtInfo {
  errors = {};
  addCourtsData = {} as addCourt;
  updateCourtsData = {} as CourtList;
  deleteCourtsData = {} as CourtDel;
  courtData = {} as ICourtTableData;

  get getErrorsCourt() {
    return this.errors;
  }
  get getCourtDataModal() {
    return this.addCourtsData;
  }
  get updateCourtDataModal() {
    return this.updateCourtsData;
  }
  get deleteCourtDataModal() {
    return this.deleteCourtsData;
  }

  get getAllCourtData() {
    return this.courtData;
  }

  // create mutation
  @Mutation
  [Mutations.ADD_COURT_DATA](data) {
    this.addCourtsData = data;
  }
  @Mutation
  [Mutations.UPDATE_COURT_DATA](data) {
    this.updateCourtsData = data;
  }
  @Mutation
  [Mutations.DELETE_COURT_DATA](data) {
    this.deleteCourtsData = data;
  }
  @Mutation
  [Mutations.COURT_LIST_DATA](data) {
    this.courtData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.ADD_COURT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/court/create`, payload)
      .then((response) => {
        this.context.commit(Mutations.ADD_COURT_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_COURT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/court/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.UPDATE_COURT_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_COURT](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/court/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.DELETE_COURT_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.COURT_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/city/court/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.COURT_LIST_DATA, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
