import { ICustomer } from "@/core/data/customers";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

interface CustomerInfo {
  customers: Array<ICustomer>;
}

@Module
export default class CustomerModules
  extends VuexModule
  implements CustomerInfo
{
  customers = [];

  /**
   * Get customer list
   * @returns array of objects
   */
  get getAllCustomers(): Array<ICustomer> {
    return this.customers;
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](customers) {
    this.customers = customers;
  }

  @Action
  [Actions.GET_CUSTOMERS](payload) {
    ApiService.setHeader();
    return ApiService.post("auth/get-data", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMERS, data.data);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_CUSTOMERS, []);
      });
  }
}
