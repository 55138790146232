import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface ISubscriberData {
  name: string;
  phone: string;
  address_1: string;
  address_2: string;
  city: string;
  postcode: string;
  plan_name: string;
}
export interface BilingList {
  name: string;
  status_name: string;
  status: string;
  id: number;
  subscriber: ISubscriberData;
}

export interface BilingInfo {
  errors: unknown;
  bilingData: IBilingTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IBilingTableData extends IBaseTable {
  data: Array<BilingList> | [];
}

@Module
export default class BilingModule extends VuexModule implements BilingInfo {
  errors = {};
  bilingData = {} as IBilingTableData;

  get getErrorsBiling() {
    return this.errors;
  }
  get getAllBilingData() {
    return this.bilingData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_BILING_LIST](data) {
    this.bilingData = data;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action

  @Action
  [Actions.BILING_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/billing/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_BILING_LIST, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
