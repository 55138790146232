import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { BASE_URL } from "../BaseUrls";

export interface addUser {
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string;
  role_id: number;
  status: number;
  image: string;
}

export interface RoleDetail {
  id: number;
  name: string;
  code: string;
  status: number;
}

export interface UserList {
  first_name: string;
  last_name: string;
  email: string;
  contact_no: string;
  role_id: number;
  status: number;
  image: string;
  id: number;
  role: RoleDetail;
}

export interface UserDel {
  id: number;
}

export interface UserInfo {
  errors: unknown;
  addUsersData: addUser;
  updateUsersData: UserList;
  deleteUserData: UserDel;
  userData: IUserTableData;
}

export interface IBaseTable {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number | 0;
}

export interface IUserTableData extends IBaseTable {
  data: Array<UserList> | [];
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
  errors = {};
  addUsersData = {} as addUser;
  updateUsersData = {} as UserList;
  deleteUserData = {} as UserDel;
  userData = {} as IUserTableData;

  get getErrorsUser() {
    return this.errors;
  }
  get addUserDataModal() {
    return this.addUsersData;
  }
  get updateUserDataModal() {
    return this.updateUsersData;
  }
  get deleteUserDataModal() {
    return this.deleteUserData;
  }

  get getAllUserData() {
    return this.userData;
  }

  // create mutation
  @Mutation
  [Mutations.SET_ADD_USER](data) {
    this.addUsersData = data;
  }
  @Mutation
  [Mutations.SET_UPDATE_USER_DATA](data) {
    this.updateUsersData = data;
  }
  @Mutation
  [Mutations.SET_DELETE_USER](data) {
    this.deleteUserData = data;
  }
  @Mutation
  [Mutations.SET_USER_LIST_DATA](data) {
    this.userData = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  // define action
  @Action
  [Actions.ADD_USER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/staff/create`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ADD_USER, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_USER_DATA](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/staff/update`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_UPDATE_USER_DATA, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_USER](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/staff/delete`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_DELETE_USER, response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.USER_LIST](payload) {
    ApiService.setHeader();
    return ApiService.post(`${BASE_URL}/admin/staff/get`, payload)
      .then((response) => {
        this.context.commit(Mutations.SET_USER_LIST_DATA, response.data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}
